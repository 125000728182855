import React, { useState, useEffect } from 'react';
import { SERVER_URL } from '../../../common/config'

import { Grid } from "@material-ui/core";
import { Button, DatePicker, DatePicker as DatePickerAnt, Form, Input, InputNumber, Modal, Select, Tag } from 'antd';
import { Table } from 'antd';
import { animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Details, OpenInNewOutlined } from '@mui/icons-material';
import ScheduleListDetailReport from './ScheduleListDetail';

const dateFormat = 'YYYY-MM-DD';

function ScheduleTargetReport() {
  const [scheduleTargetData, setScheduleTargetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterList, setFilterList] = useState({
    limit: 10,
    offset: 0,
    user_id: null,
    full_name: null,
    start_date: null,
    end_date: null
  })
  const [userData, setUserData] = useState([]);
  const [modalData, setModalData] = useState({});

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const body = {
        company_id: localStorage.getItem('company_id'),
        ...filterList,
      }
      const { data } = await axios.post(`${SERVER_URL}getScheduleTargetReportByCompanyId`, body)
      setScheduleTargetData(data?.payload);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeSearchValue = (type) => (value) => {
    if (type === 'user') {
      setFilterList((prev) => ({
        ...prev,
        user_id: value
      }))
    } else if (type === 'date') {
      setFilterList((prev) => ({
        ...prev,
        start_date: dayjs(value?.[0], dateFormat),
        end_date: dayjs(value?.[1], dateFormat),
      }))
    }
  }

  const getUserByCompanyId = async () => {
    try {
      const body = {
        company_id: localStorage.getItem('company_id')
      };
      const { data } = await axios.post(`${SERVER_URL}getUserById`, body);
      setUserData(
        data.map(item => ({ label: item.full_name, value: item.user_id }))?.sort?.((a, b) => a.label?.localeCompare?.(b.label))
      );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [filterList]);

  useEffect(() => {
    getUserByCompanyId();
  }, []);

  const handleOpenScheduleListDetail = ({
    startDate,
    endDate,
    userId
  }) => {
    setModalData({
      startDate,
      endDate,
      userId,
      isOpen: true
    })
  }

  const handleCloseScheduleListDetail = () => {
    setModalData({})
  }

  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500, // You can adjust the duration of the scroll animation
      smooth: 'easeInOutQuart', // You can choose different easing functions
    });
  };

  const columnsTwo = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Visit Total',
      dataIndex: 'visit_total',
      key: 'visit_total',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage_achieved',
      key: 'percentage_achieved',
      render: (value) => {
        return <div>{value}%</div>;
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (value) => {
        return <div>{moment(value).format('DD MMM YYYY')}</div>; // Return null if check_in_datetime is not defined>;
      }
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (value) => {
        return <div>{moment(value).format('DD MMM YYYY')}</div>; // Return null if check_in_datetime is not defined>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'end_date',
      render: (_, record) => {
        return <OpenInNewOutlined sx={{ cursor: 'pointer' }} onClick={() => handleOpenScheduleListDetail({ startDate: record?.start_date, endDate: record?.end_date, userId: record?.user_id })} />
      }
    },
  ];

  return (
    <div>
      <Grid container spacing={4} style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: '18px', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Form.Item label="User:" name="user_id" style={{ marginBottom: 0 }}>
              <Select
                placeholder="Select User"
                style={{ width: 170 }}
                value={filterList.user_id}
                onChange={handleChangeSearchValue('user')}
                options={userData}
                allowClear
              />
            </Form.Item>
            <Form.Item label="Date:" name="date" style={{ marginBottom: 0 }}>
              <DatePicker.RangePicker
                onChange={handleChangeSearchValue('date')}
                value={[dayjs(filterList.start_date, dateFormat), dayjs(filterList.end_date, dateFormat)]}
              />
            </Form.Item>
          </Box>
        </Box>
        <Grid item xs={12} md={12}>
          <Table
            loading={isLoading}
            columns={columnsTwo}
            dataSource={scheduleTargetData?.data}
            pagination={{
              showSizeChanger: false,
              total: scheduleTargetData.total,
              current: filterList.offset / filterList.limit + 1,
              onChange: (page) => {
                setFilterList({
                  ...filterList,
                  offset: (page - 1) * filterList.limit
                })

                handleScrollToTop()
              },
            }}
            style={{ border: '0.1px solid #D3D3D3', borderRadius: "5px" }}
          />
        </Grid>
      </Grid>
      <ScheduleListDetailReport
        isOpen={modalData?.isOpen}
        onClose={handleCloseScheduleListDetail}
        filter={modalData}
      />
    </div>
  );
}

export default ScheduleTargetReport;