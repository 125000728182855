import React, { useState, useEffect } from 'react';
import { SERVER_URL } from '../../common/config'

import { Grid } from "@material-ui/core";
import { Button, DatePicker, DatePicker as DatePickerAnt, Form, Input, InputNumber, Modal, Select, Tag } from 'antd';
import { Table } from 'antd';
import { animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { DeleteOutlineOutlined, EditNoteOutlined } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';

const { confirm } = Modal;

const dateFormat = 'YYYY-MM-DD';

function ScheduleTargetPage() {
  const [scheduleTargetData, setScheduleTargetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filterList, setFilterList] = useState({
    limit: 10,
    offset: 0,
    user_id: null,
    full_name: null,
    start_date: null,
    end_date: null
  })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    user_id: null,
    target: null,
    start_date: null,
    end_date: null,
  });
  const [userData, setUserData] = useState([]);

  const isSubmitDisabled = !formData.user_id || !formData.target || !formData.start_date || !formData.end_date || isSubmitting;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const body = {
        company_id: localStorage.getItem('company_id'),
        ...filterList,
      }
      const { data } = await axios.post(`${SERVER_URL}getScheduleTargetByCompanyId`, body)
      setScheduleTargetData(data?.payload);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (type) => (value) => {
    if (type === 'target') {
      setFormData((prev) => ({
        ...prev,
        target: value
      }))
    } else if (type === 'user') {
      setFormData((prev) => ({
        ...prev,
        user_id: value
      }))
    } else if (type === 'date') {
      setFormData((prev) => ({
        ...prev,
        start_date: dayjs(value?.[0], dateFormat),
        end_date: dayjs(value?.[1], dateFormat),
      }))
    }
  }

  const handleChangeSearchValue = (type) => (value) => {
    if (type === 'user') {
      setFilterList((prev) => ({
        ...prev,
        user_id: value
      }))
    } else if (type === 'date') {
      setFilterList((prev) => ({
        ...prev,
        start_date: dayjs(value?.[0], dateFormat),
        end_date: dayjs(value?.[1], dateFormat),
      }))
    }
  }

  const getUserByCompanyId = async () => {
    try {
      const body = {
        company_id: localStorage.getItem('company_id'),
      };
      const { data } = await axios.post(`${SERVER_URL}getUserById`, body);
      setUserData(
        data.map(item => ({ label: item.full_name, value: item.user_id }))?.sort?.((a, b) => a.label?.localeCompare?.(b.label))
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      if (formData.id) {
        await axios.post(`${SERVER_URL}updateScheduleTarget`, formData);
        toast.success('Successfully updated target');
      } else {
        await axios.post(`${SERVER_URL}createScheduleTarget`, formData);
        toast.success('Successfully created target');
      }
      handleCloseModal();
      fetchData();
    } catch (error) {
      console.log(error);
      toast.error('Failed to create target');
    } finally {
      setIsSubmitting(false)
      handleCloseModal();
    }
  };

  const handleDelete = async (id) => {
    confirm({
      title: 'Are you sure you want to delete this target?',
      type: 'danger',
      closable: true,
      maskClosable: true,
      okType: 'danger',
      okText: 'Delete',
      onOk: async () => {
        try {
          setIsSubmitting(true)
          await axios.post(`${SERVER_URL}deleteScheduleTarget`, { id });
          toast.success('Successfully deleted target');
          fetchData();
        } catch (error) {
          console.log(error);
          toast.error('Failed to delete target');
        } finally {
          setIsSubmitting(false)
        }
      },
    })
  }

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: null,
      user_id: null,
      target: null,
      start_date: null,
      end_date: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, [filterList]);

  useEffect(() => {
    getUserByCompanyId();
  }, []);

  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500, // You can adjust the duration of the scroll animation
      smooth: 'easeInOutQuart', // You can choose different easing functions
    });
  };

  const handleEdit = (id) => {
    const data = scheduleTargetData?.data?.find((item) => item.id === id);
    setFormData({
      id: data.id,
      user_id: data.user_id,
      target: data.target,
      start_date: dayjs(data.start_date, dateFormat),
      end_date: dayjs(data.end_date, dateFormat),
    });
    handleShowModal();
  }

  const columnsTwo = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (value) => {
        return <div>{moment(value).format('DD MMM YYYY')}</div>;
      }
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (value) => {
        return <div>{moment(value).format('DD MMM YYYY')}</div>;
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (value) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <EditNoteOutlined
              style={{ cursor: 'pointer', color: '#1677ff', fontSize: '28px' }}
              onClick={() => handleEdit(value)}
            />
            <DeleteOutlineOutlined
              style={{ cursor: 'pointer', color: 'red' }}
              onClick={() => handleDelete(value)}
            />
          </Box>
        );
      }
    },
  ];

  return (
    <div>
      <ToastContainer />
      <Grid container spacing={4} style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '24px' }}>
        <Typography fontSize="20px" fontWeight="600" marginBottom="18px">Target Schedule</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: '18px', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Form.Item label="User:" name="user_id" style={{ marginBottom: 0 }}>
              <Select
                placeholder="Select User"
                style={{ width: 170 }}
                value={filterList.user_id}
                onChange={handleChangeSearchValue('user')}
                options={userData}
                allowClear
              />
            </Form.Item>
            <Form.Item label="Date:" name="date" style={{ marginBottom: 0 }}>
              <DatePicker.RangePicker
                onChange={handleChangeSearchValue('date')}
                value={[dayjs(filterList.start_date, dateFormat), dayjs(filterList.end_date, dateFormat)]}
              />
            </Form.Item>
          </Box>
          <Button onClick={handleShowModal} type="primary">
            Create New
          </Button>
        </Box>
        <Grid item xs={12} md={12}>
          <Table
            loading={isLoading}
            columns={columnsTwo}
            dataSource={scheduleTargetData?.data}
            pagination={{
              showSizeChanger: false,
              total: scheduleTargetData.total,
              current: filterList.offset / filterList.limit + 1,
              onChange: (page) => {
                console.log(page)
                console.log(filterList.limit)
                setFilterList({
                  ...filterList,
                  offset: (page - 1) * filterList.limit
                })

                handleScrollToTop()
              },
            }}
            style={{ border: '0.1px solid #D3D3D3', borderRadius: "5px" }}
          />
        </Grid>
      </Grid>
      <Modal
        title={formData.id ? 'Edit Target' : 'Create New Target'}
        open={isModalOpen} onOk={handleSubmit}
        onCancel={handleCloseModal}
        okButtonProps={{disabled: isSubmitDisabled, loading: isSubmitting}}
        okText="Save"
      >
        <Box sx={{ paddingY: '18px' }}>
          <Form
            name="schedule_target"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item label="User:">
              <Select
                placeholder="Select User"
                style={{ width: 170 }}
                onChange={handleChange('user')}
                options={userData}
                disabled={!!formData.id}
                value={formData.user_id}
              />
            </Form.Item>
            <Form.Item label="Target:">
              <InputNumber
                placeholder="Input Target"
                onChange={handleChange('target')}
                value={formData.target}
              />
            </Form.Item>
            <Form.Item label="Date:">
              <DatePicker.RangePicker
                onChange={handleChange('date')}
                value={
                  formData.start_date && formData.end_date ? [dayjs(formData.start_date, dateFormat), dayjs(formData.end_date, dateFormat)] : []
                }
              />
            </Form.Item>
          </Form>
        </Box>
      </Modal>
    </div>
  );
}

export default ScheduleTargetPage;