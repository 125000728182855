import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";

// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";

// components
import Widget from "../../../components/Widget/Widget";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { GOOGLE_MAP_API_KEY, SERVER_URL } from "../../../common/config";
import { Input, Switch, Typography, Button, TimePicker } from "antd";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { useRef } from "react";
import dayjs from 'dayjs'

const { Text } = Typography;

function FeatureFlagDetailPage(props) {
    var classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({});
    const { company_id } = useParams();
  
    useEffect(() => {
      getCompanyDetail();
    }, []);
  
    //Show notification
    const notify = (message) => toast(message);
  
    const getCompanyDetail = () => {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          company_id: company_id,
        }),
      };
      fetch(`${SERVER_URL}getCompanyById`, requestOptions).then(
        async (response) => {
          const data = await response.json();
          setForm({
            ...data,
          });
        }
      ).finally(() => {
        setIsLoading(false);
      });
    };
  
    const onSave = () => {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          allow_self_create_client: form.allow_self_create_client,
          canvas_checkin_feature: form.canvas_checkin_feature,
          multiple_absent_feature: form.multiple_absent_feature,
          switch_account_feature: form.switch_account_feature,
          reschedule_feature: form.reschedule_feature,
          leave_request_feature: form.leave_request_feature,
          company_id: company_id
        }),
      };
      fetch(`${SERVER_URL}updateFeatureFlag`, requestOptions)
        .then(async (response) => {
          const data = await response.json();
          notify("Successfully Saved ");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

  return (
    <Container>
      <PageTitle title="Feature Flag Detail" />
      <Grid container>
        <ToastContainer />
        <Grid item xs={12} md={12}>
          <Widget title="" disableWidgetMenu>
            <Grid container direction="column" spacing={1}>
              <div className={classes.formControl}>
                <Text className={classes.label} style={{ marginRight: "6px" }}>Allow Self Create Client</Text>
                <div>
                  <Switch
                    checked={form.allow_self_create_client}
                    onChange={(checked) =>
                      setForm((prev) => ({ ...prev, allow_self_create_client: checked }))
                    }
                    name="allow_self_create_client"
                  />
                </div>
              </div>
              <div className={classes.formControl}>
                <Text className={classes.label} style={{ marginRight: "6px" }}>Canvas Checkin</Text>
                <div>
                  <Switch
                    checked={form.canvas_checkin_feature}
                    onChange={(checked) =>
                      setForm((prev) => ({ ...prev, canvas_checkin_feature: checked }))
                    }
                    name="canvas_checkin_feature"
                  />
                </div>
              </div>
              <div className={classes.formControl}>
                <Text className={classes.label} style={{ marginRight: "6px" }}>Multiple Absent</Text>
                <div>
                  <Switch
                    checked={form.multiple_absent_feature}
                    onChange={(checked) =>
                      setForm((prev) => ({ ...prev, multiple_absent_feature: checked }))
                    }
                    name="multiple_absent_feature"
                  />
                </div>
              </div>
              <div className={classes.formControl}>
                <Text className={classes.label} style={{ marginRight: "6px" }}>Switch Account</Text>
                <div>
                  <Switch
                    checked={form.switch_account_feature}
                    onChange={(checked) =>
                      setForm((prev) => ({ ...prev, switch_account_feature: checked }))
                    }
                    name="switch_account_feature"
                  />
                </div>
              </div>
              <div className={classes.formControl}>
                <Text className={classes.label} style={{ marginRight: "6px" }}>Reschedule</Text>
                <div>
                  <Switch
                    checked={form.reschedule_feature}
                    onChange={(checked) =>
                      setForm((prev) => ({ ...prev, reschedule_feature: checked }))
                    }
                    name="reschedule_feature"
                  />
                </div>
              </div>
              <div className={classes.formControl}>
                <Text className={classes.label} style={{ marginRight: "6px" }}>Leave Request</Text>
                <div>
                  <Switch
                    checked={form.leave_request_feature}
                    onChange={(checked) =>
                      setForm((prev) => ({ ...prev, leave_request_feature: checked }))
                    }
                    name="leave_request_feature"
                  />
                </div>
              </div>
            </Grid>
            <Grid item style={{ marginTop: "18px" }}>
              <Button
                disabled={isLoading}
                type="primary"
                className={classes.button}
                onClick={() => onSave()}
              >
                Save
              </Button>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FeatureFlagDetailPage;
