import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@mui/material'
import { ButtonGroup } from '@mui/material'
import React, { useRef, useState } from 'react'
import CSVReader from 'react-csv-reader'
import { toast, ToastContainer } from 'react-toastify';
import { SERVER_URL } from '../../common/config';
import ExampleData from '../../assets/csv/example_schedule.csv'
import clearSpace from '../../utils/clearSpace';
import moment from 'moment';

const useStyles = makeStyles(theme => {
  return {
      container: {
          display: 'flex',
          flexDirection: 'column'
      },
      item: {
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: '12px 18px',
          border: '1px solid black',
          borderRadius: '9px',
          marginBottom: '18px'
      },
      languageOption: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '12px 18px',
        borderRadius: '9px',
        marginBottom: '18px'
    },
    button: {
      fontSize: 11,
      alignItems: 'center',
    }
  }
});

const ImportSchedule = () => {
  const [isImportLoading, setIsImportLoading] = useState(false);
  const csvRef = useRef();

  const classes = useStyles();

  const addWithCSV = (data) => {
    const temp = []
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      if (
          data[0][0] !== 'user_id' ||
          data[0][1] !== 'client_id' ||
          data[0][2] !== 'schedule_datetime'
        ) {
        toast('format is not valid')
        return false
      }
      if (!row[0]) {
        toast(`user_id cannot be empty`)
        return false
      }
      if (!row[1]) {
        toast(`client_id cannot be empty`)
        return false
      }
      if (!row[2]) {
        toast(`schedule_datetime cannot be empty`)
        return false
      }
      let saveData = {
        user_id: clearSpace(row[0]),
        client_id: clearSpace(row[1]),
        schedule_datetime: moment(row[2]).format('YYYY-MM-DD HH:mm:ss'),
        reason: clearSpace(row[3]),
        products: clearSpace(row[4])?.split?.(','),
        predicted_time_spent: 1,
      }
      // toast(`${data[i]} pada row ${i} tidak boleh kosong`)
      temp.push(saveData)
    }
    const reqOption = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        company_id: parseInt(localStorage.getItem('company_id')),
        data: temp
      })
    }
    setIsImportLoading(true)
    fetch(`${SERVER_URL}importSchedule`, reqOption)
      .then(async response => {
        const data = await response.json();
        console.log("Response Data=============>", data)

        if (data?.payload?.successes?.length) {
          toast(`Success Import: ${data?.payload?.successes?.length} item`)
        }
        if (data?.payload?.errors?.length) {
          // eslint-disable-next-line no-unused-expressions
          data?.payload?.errors?.forEach?.((item) => {
            toast(`Row ${item.index}: ${item.error}`)
          })
        }
      })
      .catch(error => {
        toast('Something went wrong!\n' + error)
        console.error('There was an error!', error);
      })
      .finally(()=> {
        setIsImportLoading(false)
    });
    csvRef.current.value = ''
}

  return (
    <>
      <ToastContainer />
      <div className={classes.container}>
        <div className={classes.item}>
          <h4>Unduh data contoh</h4>
          <Button className={classes.button} href={ExampleData} download="example_data" variant="outlined" color="primary">
            Unduh Contoh
          </Button>
        </div>
        <div className={classes.item}>
          <h4>Import file dengan format CSV</h4>
          <Button className={classes.button} variant="contained" color="primary" component="label" disabled={isImportLoading}>
            {
              isImportLoading
                ?
                <CircularProgress size="23px" style={{ color: 'white' }} />
                :
                'Unggah File (.csv)'
            }
            <CSVReader inputRef={csvRef} inputStyle={{ display: 'none' }} onFileLoaded={(data) => addWithCSV(data)} />
          </Button>
        </div>
      </div>
    </>
  )
}

export default ImportSchedule