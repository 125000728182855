import React, { useState, useEffect } from 'react';
import { SERVER_URL } from '../../../common/config'

import { Grid } from "@material-ui/core";
import { Modal, Table } from 'antd';
import { animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import moment from 'moment';

function ScheduleListDetailReport({
  isOpen,
  onClose,
  filter,
}) {
  const [scheduleTargetData, setScheduleTargetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setScheduleTargetData([]);
      setIsLoading(true);
      const body = {
        company_id: localStorage.getItem('company_id'),
        user_id: filter?.userId,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        status: 'success',
      }
      const { data } = await axios.post(`${SERVER_URL}getScheduleWithFilter`, body)
      setScheduleTargetData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Client',
      dataIndex: 'client_entity_name',
      key: 'client_entity_name',
    },
    {
      title: 'Client Address',
      dataIndex: 'client_address',
      key: 'client_address',
    },
    {
      title: 'Check In',
      dataIndex: 'activity',
      key: 'activity',
      render: (value) => {
        return <div>{value?.check_in_datetime ? moment(value?.check_in_datetime).format('DD MMM YYYY HH:mm') : '-'}</div>;
      }
    },
    {
      title: 'Check out',
      dataIndex: 'activity',
      key: 'activity',
      render: (value) => {
        return <div>{value?.check_out_datetime ? moment(value?.check_out_datetime).format('DD MMM YYYY HH:mm') : '-'}</div>;
      }
    },
  ];

  return (
    <Modal width={600} title="Schedule List" open={isOpen} onCancel={onClose} okButtonProps={{ style: { display: 'none' } }}>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={scheduleTargetData?.data}
        pagination={{
          showSizeChanger: false,
          total: scheduleTargetData.total,
          pageSize: 5
        }}
        style={{ border: '0.1px solid #D3D3D3', borderRadius: "5px" }}
      />
    </Modal>
  );
}

export default ScheduleListDetailReport;