import React from "react";
import { Tabs } from 'antd';
import { useSelector } from "react-redux";

import Schedule from './ScheduleView';
import Absent from './AbsentView';
import CalendarView from './CalendarView'
import AddSchedule from './AddSchedule'
import ScheduleReport from "../Report/ScheduleReport/ScheduleReport";
import ImportSchedule from "./ImportSchedule";

const ScheduleView = () => {

  const isLoading = useSelector(state => state.scheduleview.loading);

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
        key: '1',
        label: 'Schedule View',
        children: <Schedule />
    },
    {
      key: '3',
      label: 'Import Schedule',
      children: <ImportSchedule />
    }
  ];

  if (isLoading === false) {
    items.push({
      key: '2',
      label: 'Add Schedule',
      children: <AddSchedule />
    });
  }

  return (
    <Tabs style={{margin:"10px"}} defaultActiveKey="1" items={items} onChange={onChange} />
  )
}

export default ScheduleView